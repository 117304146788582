.App {
    text-align: center;
}


.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.Mui-focused {
    outline: 0 !important;
    border: none !important;
}

input:focus {
    outline: none !important;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.verification-code-container input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.verification-code-container input[type=number] {
    -moz-appearance: textfield;
}


.password-requirements {
    font-size: 0.875rem;
    margin-top: 0.625rem;
}


.password-requirements ul {
    margin: 0;
    padding: 0 0 0 1.25rem;
}

.password-requirements li {
    list-style: disc;
    margin-bottom: 0.313rem;
    color: #75797A;
    font-family: Poppins;
}

.auth-label {
    margin-top: 0.875rem;

}

.react-datepicker__navigation{
    padding: 0 1.5rem !important;
}
.vertica-date-time .react-datepicker {
    background-color: #F2F5FA !important;
    width: 100% !important;
    height: 305px !important;

}

.vertica-date-time .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    height: 2rem !important;
    line-height: 1.313rem !important;
    font-weight: 400 !important;
    /* identical to box height */
    display: flex;
    align-items: center;
    justify-content: center;
    /*background-color: #E9E9EB!important;*/
    color: #4C488F !important;
    font-size: 1.125rem !important;
}

.react-datepicker-popper {
    background-color: #F2F5FA !important;
    width: 368px !important;
    height: 200px !important;
}

.react-datepicker__month-container{
    width: 368px !important;
    min-height: 200px !important;
    padding: 1rem 1.5rem !important; ;
}
.search-input-date .react-datepicker__month-container {
    width: 100% !important;
    height: 100% !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 2.6rem !important;
    line-height: 2rem !important;
}


.react-datepicker__header {
    background-color: transparent !important;
    border-bottom: none !important;
    padding-top: 0 !important;
}

.react-datepicker__month, .react-datepicker__monthPicker{
    min-width: 318px !important;
}

.react-datepicker__month-wrapper{
    width:100% !important;
    min-width: 318px !important;
}

.react-datepicker-popper {
    display: flex;
    flex-wrap: wrap;
    max-width: 350px; /* Adjust this width as needed */
}



.search-input-date .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    /*    height: 3.5rem !important;
        font-size: 1.125rem !important;
        line-height: 1.313rem !important;
        font-weight: 400 !important;
        margin-left: 1.5rem !important;
        !* identical to box height *!

        display: flex;
        align-items: center;

        color: #444444 !important;*/
}

.react-datepicker__current-month {
    background-color: white!important;
    color: #00A4BD;
    font-size: 80px;
    /*    text-align: center;
        leading-trim: both;
        text-edge: cap;
        !* body *!
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px; !* 140% *!*/
}

.react-datepicker__navigation {
    top: 6% !important;
    color: #52007E !important;
    /*background-color: #E9E9EB!important;*/
}

.react-datepicker__navigation:hover *::before{
    border-color: #52007E !important;
}
.react-datepicker__navigation-icon::before{
    border-color: #52007E !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: #52007E !important;
}

.css-zun73v.Mui-checked, .css-zun73v.MuiCheckbox-indeterminate{
    color: #52007E !important;
}

.react-datepicker__month-text--keyboard-selected{
    background-color: #52007E1A !important;
    color: #FEFEFE!important;
    width: 70px;
    height:30px;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
}


.react-datepicker__month {
    width: calc(100% / 4)!important;;
    flex: 0 1 25%!important;
    margin:0 !important;
}

.react-datepicker__month-text{
    width: 100px !important;
    height:30px;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #070707;
    text-align: center;

    font-variant-numeric: lining-nums proportional-nums;
    /* body */
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
}



.search-input-date .react-datepicker__navigation--previous {
    left: 80% !important;
}

.react-datepicker__triangle::after {
    display: none;
}

.react-datepicker__triangle::before {
    display: none;
}

.react-datepicker-popper {
    padding-top: 0 !important;
}

.react-datepicker__day--in-range {
    width:41px !important;
    height:39px !important;
    background-color: #52007E1A !important;
    color: #000000 !important;
    border-radius:  78px !important;
    font-size: 18px!important;
    font-style: normal!important;
    font-weight: 400!important;
}

.react-datepicker__day {
    width:41px !important;
    height:39px !important;
    color: #000000 !important;
    border-radius:  78px !important;
    font-size: 18px!important;
    font-style: normal!important;
    font-weight: 400!important;
    padding-top: 3px !important;
}


.react-datepicker__day--range-start{
    background-color: #52007E !important;
    color: #FFFFFF !important;
}

.react-datepicker__day--range-end{
    background-color: #52007E !important;
    color: #FFFFFF !important;
}

.react-datepicker__day--in-selecting-range{
    background-color: #52007E1A !important;
    color: #000000 !important;
}
.react-datepicker__day--selecting-range-end{
    background-color: #52007E !important;
    color: #FFFFFF !important;
}
.react-datepicker__day--selecting-range-start{
    background-color: #52007E !important;
    color: #FFFFFF !important;
}

.react-datepicker__day--keyboard-selected{
    background-color: #52007E !important;
    color: #FFFFFF !important;
}

.tableToolTip{
    border: 1px solid lightgray;
    margin-top: 0.7rem;
    display: inline-flex;
    padding: 12px 20px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(142, 141, 208, 0.16);
}


.react-datepicker-wrapper{
    border: 1px solid #E9E9EB;
    /*border-color: #E9E9EB;*/
}


.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 12px 24px !important;
    border-radius: 8px!important;
}

.css-i4bv87-MuiSvgIcon-root{
    fill: #52007E !important;
}

.css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected{
    background-color: #52007E!important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    color: #52007E!important;
}


::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 18px;
    max-height: 106px!important; /* Height of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #D9D9D9;
}

/*.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    border-color: #E9E9EB;
}*/

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: 1px solid #E9E9EB!important;
    /*border-width: 1px solid #E9E9EB!important;*/
}

.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-colorPrimary .Mui-focused{
    border: 1px solid #E9E9EB;
}