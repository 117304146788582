@font-face {
    font-family: Roboto;
    src: url(./Roboto-Regular.ttf);
}
@font-face {
    font-family: Roboto-medium;
    src: url(./Roboto-Medium.ttf);
}
@font-face {
    font-family: Roboto-bold;
    src: url(./Roboto-Bold.ttf);
}


@font-face {
    font-family: Poppins;
    src: url(./Poppins-Regular.ttf);
}
@font-face {
    font-family: Poppins-medium;
    src: url(./Poppins-Medium.ttf);
}
@font-face {
    font-family: Poppins-bold;
    src: url(./Poppins-Bold.ttf);
}
@font-face {
    font-family: Poppins;
    src: url(./Poppins-Regular.ttf);
}
@font-face {
    font-family: Poppins-medium;
    src: url(./Poppins-Medium.ttf);
}
@font-face {
    font-family: Poppins-bold;
    src: url(./Poppins-Bold.ttf);
}
